import React from 'react'
import { Link } from 'gatsby'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styles from './header.module.scss'

import menu_styles from './hamburger.module.scss'

import MediaQuery from 'react-responsive'

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {value: false}
        
        this.onChange = this.onChange.bind(this);
        this.unCheck = this.unCheck.bind(this);

    }

    onChange(e){
        let value = this.state.value;
        value = e.target.checked;
        this.setState({value});
        this.hideShowSidebar(this.state.value);

        console.log('checkbox change = ' + this.state.value)
    }

    unCheck(){
        let value = this.state.value;
        value = false;
        //this.setState({value})
        this.hideShowSidebar(this.state.value);

        console.log('uncheck = ' + this.state.value)
    }

    hideShowSidebar(value) {
        const documentWidth = document.documentElement.clientWidth;
        const windowWidth = window.innerWidth;
        const scrollBarWidth = windowWidth - documentWidth;
        
        if(value) {
            document.body.style.overflow = 'auto';
            document.body.style.paddingRight = '0px';
        } else {
            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = `${scrollBarWidth}` + 'px';
            document.body.style.background = '#0F0F0F';
        }
    }


    render() {
        
        return (
            <header className={styles.header}>
                
                <div className={styles.mindbuffer_logo}>
                    <Link className={styles.navItem} activeClassName={styles.activeNavItem} to="/">
                        <Img fixed={this.props.logo_image.childImageSharp.fixed} alt="mindbuffer_logo" />
                    </Link>
                </div>

                <MediaQuery query="(max-width: 800px)">
                    <div className={menu_styles.menu_wrap}>
                        <input 
                            type="checkbox" 
                            className={menu_styles.toggler} 
                            id="burger_checkbox"
                            checked={this.state.value} 
                            onChange={this.onChange}/>
                        
                        <div className={menu_styles.hamburger}><div></div></div>
                        <div className={menu_styles.menu}>
                            <div>
                                <div>
                                    <ul onClick={this.unCheck} >
                                        <li><Link className={styles.menuNavItem} activeClassName={styles.activeNavItem} to="/works">WORKS</Link></li>
                                        <li><Link className={styles.menuNavItem} activeClassName={styles.activeNavItem} to="/studio">STUDIO</Link></li>
                                        <li><Link className={styles.menuNavItem} activeClassName={styles.activeNavItem} to="/research">RESEARCH</Link></li>
                                        <li><Link className={styles.menuNavItem} activeClassName={styles.activeNavItem} to="/contact">CONTACT</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </MediaQuery>

                <MediaQuery query="(min-width: 801px)">
                <div className={styles.text}>
                    <nav>
                        <ul className={styles.navList}>
                            <li>
                                <Link className={styles.navItem} activeClassName={styles.activeNavItem} to="/works">WORKS</Link>
                            </li>
                            <li>
                                {/* <a className={styles.navItem} activeClassName={styles.activeNavItem} href="https://studio.mindbuffer.net" aria-label="Studio" target="_blank" rel="noopener noreferrer">
                                    STUDIO
                                </a> */}
                                <Link className={styles.navItem} activeClassName={styles.activeNavItem} to="/studio">STUDIO</Link>
                            </li>
                            <li>
                                <Link className={styles.navItem} activeClassName={styles.activeNavItem} to="/research">RESEARCH</Link>
                            </li>
                            <li>
                                <Link className={styles.navItem} activeClassName={styles.activeNavItem} to="/contact">CONTACT</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                </MediaQuery>

            </header>
        )
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                logo_image: file(relativePath: { eq: "images/mindbuffer_logo_alpha.png" }) {
                    childImageSharp {
                        fixed(width: 40, height: 40) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                site {
                    siteMetadata {
                        author
                        email
                    }
                }
            }
        `}
        render={(data) => (
            <Header site={data.site} logo_image={data.logo_image} />
        )}
    />
)
