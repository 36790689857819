import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styles from './footer.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class Footer extends React.Component {
    render() {
        return (
            <footer className={styles.footer}>
                <div></div>
                <div>
                    {this.props.site.siteMetadata.email}
                    <br></br>
                    {this.props.site.siteMetadata.phoneNumber}
                    <br></br>
                    {this.props.site.siteMetadata.city}
                </div>

                <div className={styles.links}>
                    <div></div>
                    <a href="https://vimeo.com/mindbuffer" aria-label="Vimeo" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={['fab', 'vimeo']} size="1x" style={{ color: "#F7F7F7" }} />                        
                    </a>

                    <a href="https://soundcloud.com/mindbuffer" aria-label="Soundcloud" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={['fab', 'soundcloud']} size="1x" style={{ color: "#F7F7F7" }} />
                    </a>

                    <a href="https://www.instagram.com/mindbuffer/" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={['fab', 'instagram']} size="1x" style={{ color: "#F7F7F7" }} />
                    </a>
                    <div></div>
                </div>

                <div>
                    © {this.props.site.siteMetadata.author} 2021
                </div>
                <div></div>
            </footer>
        )
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                site {
                    siteMetadata {
                        author
                        email
                        phoneNumber
                    }
                }
            }
        `}
        render={(data) => (
            <Footer site={data.site} />
        )}
    />
)

