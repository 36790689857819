import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'

import Layout from "../components/layout"
import SEO from '../components/seo'
import styles from './works.module.scss'
import studio_styles from './studio.module.scss'

const StudioPage = () => {
    // Fetch the Markdown post data
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    keywords
                }
            }
            allMarkdownRemark 
            (sort: { fields: [frontmatter___date], order: DESC })
            {
                edges {
                    node {
                        frontmatter {
                            title
                            date
                            tags
                            image: featured {
                                childImageSharp {
                                    fluid(
                                        maxWidth: 2000, 
                                        maxHeight: 1000, 
                                        quality: 100,
                                        cropFocus: CENTER) 
                                    {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `)

    return (
        <Layout>
            <SEO title="Works" keywords={data.site.siteMetadata.keywords} />

            <div className={studio_styles.featured}>
                
                <div className={studio_styles.video_inner}>
                        <iframe title="MindBuffer Studio Art Installations" src="https://player.vimeo.com/video/346390787?autoplay=1&loop=1&autopause=0?muted=1&background=1" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen>
                        </iframe>
                </div>
                
                <div className={studio_styles.nested_row1}>
                    <div className={studio_styles.banner_description}>
                        <h2>
                            We are an award-winning audiovisual research and digital design studio, specialising in immersive interactive art installations.
                        </h2>
                    </div>
                </div>
            </div>
            
            <div className={studio_styles.description}>
                <p>
                    Our studio combines many disciplines to work at the intersection of art and technology. These include software development, music composition and sound design, interaction design, lighting, visuals, and industrial design.
                
                    We specialise in creating interactive experiences for museums and cultural institutions, corporate brands and advertising agencies. Services that we offer include:
                    <b> Museum and Exhibition Design</b>, <b>Interactive Art Installations</b>, <b>Architectural Projection Mapping</b>, <b>Audiovisual Systems Design</b>, <b>Consulting and Technical Advice</b>.
                </p>
                <p>
                    MindBuffer was founded in 2010 by Joshua Batty and Mitchell Nordine and now includes Tom Gowan. We are based in Berlin, Germany and also in Melbourne, Australia. We would love to discuss your next project with you, <Link className={styles.navItem} activeClassName={styles.activeNavItem} to="/contact">get in touch</Link>. 
                </p>
            </div>

            {data.allMarkdownRemark.edges.map((edge) => {
                if (edge.node.frontmatter.tags[1] == "Studio") {
                    return (
                        <div className={styles.link}>
                            <Link to={`/${edge.node.fields.slug}`}>
                                <div className={styles.featured_work_item}>
                                    <div>
                                        <Img fluid={edge.node.frontmatter.image.childImageSharp.fluid} alt="mindbuffer_logo" />
                                    </div>

                                    <div className={styles.titles}>
                                        <div className={styles.section}>
                                            <h1>{edge.node.frontmatter.title}</h1>
                                        </div>
                                        <div className={styles.section}>
                                            <h2>{edge.node.frontmatter.tags[0]}</h2>
                                        </div>
                                    </div>

                                </div>
                            </Link>
                        </div>
                    )
                }
            })}
        </Layout>
    )
}

export default StudioPage

